<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgMsgClose"></DlgMessage>
        <div class="pa-3">
          <v-toolbar>
            <v-toolbar-title>Recent Customers</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-title>
        <v-card-title>
            <DlgExportExcel ref="dlgExportExcel" :tblHeaders="tblHeaders" :tblBody="tblBody" filename="customer_recent_list" bookType="xlsx"></DlgExportExcel>
        </v-card-title>
        <v-data-table
        v-model="selected"  
        ref="vuetable"
        item-key="created_date"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"    
        @click:row="openDetail"
        ></v-data-table>
        </v-card>
        </div>

    </v-card>

</template>
<script>
import ApCustomerService from '@/services/ApCustomerService'
import DlgMessage from "@/components/DlgMessage";
import DlgExportExcel from '@/components/DlgExportExcel'
export default {
    components:{DlgMessage,DlgExportExcel},
    data() {
        return{
            //breadcrumb
            items: [
              {text:'Customers',disabled: false,href:'/customers',},
              {text:'Recent List',disabled: false,href:'#',},
              ],
            selected: [],
            tblHeaders:[
                { text:"Login Id",value: "login_id"  },
                { text:"Full Name", value:"full_name" },
                { text:"Cell Phone #", value:"cell_phone" },
                { text:"Point Balance", value:"point_balance" },
                { text:"Status", value:"mbr_status" },
                { text:"Reg. Date", value:"mbr_registration_date" },
            ],
            tblBody:[],
            tblSearch:'',
            loadingFlag : false,

        }
    },
    async created() {
      this.refreshDataTable();
    },
    methods:{
            async refreshDataTable(){

                this.loadingFlag = true
                let title = 'System';
                try{
                    ApCustomerService.list_customer(this.form).then((res) => {
                        this.tblBody = res.data.data;
                        this.loadingFlag = false;
                    }).catch((e)=>{
                            //console.log(e.response);
                        if(e.response.data.error_message)
                            this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                        this.loadingFlag = false;
                    })
                        ;
                } catch(e) {
                    if(e)
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
                    this.loadingFlag = false;
                } 
        
            },
            openDetail(value){
                //console.log(value)
                this.$router.push({ name: 'customers-details', params: { customer_id: value.customer_id } })
                //this.$router.push("/merchants/detail/" + value.merchant_id);
            },
            dlgMsgClose(){
            },

    }

}
</script>